<section class="section">

    <div class="container mt-60 main-container contenedor2"
      [ngClass]="{'margin-bottom-140': medio === 140}">
      <!-- <div class="row align-items-center">
        
  
      </div> -->

      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title-credit mb-4">
              <h2 class="title-credit mb-4">¡Tu crédito fue <span class="aprobado">aprobado!</span></h2>
          </div>

          @if(medio === 140){
              <div class="mb-4 pb-2 icon-container">
                <div class="icon-credito">
                  <img class="img-phone" src="./../../../../assets/images/creditos/phoneimg.png" alt="Phone Icon">
                  <p class="text-justify mb-0 mx-auto">Descarga la app y desembolsa tu crédito en nuestra <b>billetera digital 24/7 sin ir a una sucursal.</b></p>
              </div>
  
                <div class="icon-credito">
                  <img class="img-moneycash" src="./../../../../assets/images/creditos/moneycash.png" alt="Money Icon">
                  <p class="text-justify mb-0 mx-auto">Vas a poder <b>retirar efectivo</b> en cualquier cajero de la red infonet usando el dinero en tu billetera.</p>
              </div>
            </div>
  
            <div class="pt-4 mb-4 pb-2 pasos-container">
                  <h4 class="mb-4">Seguí estos pasos:</h4>
                  <ol>
                      <li>Descarga la app</li>
                      <li>Create una cuenta</li>
                      <li>Valida tu identidad</li>
                      <li>Activá la billetera</li>
                      <li>Desembolsa tu crédito </li>
                  </ol>
            </div>
  
            <div class="descargar-container">
              <a href="https://play.google.com/store/apps/details/Crediagil?id=py.com.procesa.crediagilapp&hl=es_CL"
                target="_blank"
                title="Descarga APP"
                type="button"
                class="btn btn-pills btn-primary mb-0"><b>DESCARGAR APP</b></a>
              <!-- <a 
              class="btn-custom btn-primary me-3"><i class="bi bi-download p-1"></i><b>DESCARGAR APP</b></a> -->
              <div class="">
                  <img class="img-playstore" src="./../../../../assets/images/creditos/disponible_playstore.png" alt="Disponible PlayStore">
              </div>
            </div>
          }
          @else{
            <div class="mb-4 pb-2 icon-store-container">
              <div class="img-store-container">
                <img class="img-store" src="./../../../../assets/images/creditos/store.png" alt="Store Icon">
                <p class="text-justify mb-0 mx-auto">Acercate a una sucursal y <b>retira tu crédito</b></p>
              </div>
            </div>

            <button type="button" (click)="onVerSucursales.emit(true)"  class="btn btn-pills btn-primary mb-0 ver-sucursales"><b>VER SUCURSALES</b></button>
          }
         
         <div class="necesitas-ayuda" [ngClass]="{'margin-botton-8rem': medio === 140}">
            <p class="text-center mb-0 mx-auto"><b>Si necesitas ayuda para desembolsar tu crédito</b>, no dudes en contactarnos al siguiente whatsapp.</p>
            <a href="https://api.whatsapp.com/send/?phone={{telef}}&text={{msnWhatsapp}}.&type=phone_number&app_absent=0" 
              type="button"
              title="Ir a Whastapp"
              target="_blank"
              class="btn btn-pills btn-primary mt-1 btn-whatsapp"
              (click)="onIrAWhatsapp.emit(true)">
              <b>WHATSAPP</b>
            </a>
         </div>
        </div>

        <!-- <a href="https://api.whatsapp.com/send/?phone={{telef}}&text={{msnWhatsapp}}.&type=phone_number&app_absent=0"
          class="btn-wsp btn-wsp-pulse" target="_blank">
          <i class="bi bi-whatsapp"></i>
        </a> -->
      </div>
    </div>
  </section>